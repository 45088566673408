<template>
    <div class="side-bar">
        <cube-scroll  ref="scroll" >
            <ul class="side-body">
                <!-- <li  @click.stop='click(10)'><div>{{$t('lang.msg160') /*首頁*/}}</div></li> -->
                <li @click.stop='click(7)' v-if="user.Username!=''" >
                    <div>${{user.Coin | numFilter}}</div>
                    <!-- <div><i class="fa-solid fa-user user-icon" ></i>  {{user.Username}}</div> -->
                    <!--
                    <div>{{user.username}} <i class="fas fa-chess-queen"></i></div>
                    <div>${{user.coin | numFilter}}</div>
                    -->
                </li>
                <li v-for="(item, index) in config.vendor_list" :key="index" @click.stop='ChangVendor(item)'>
                    <div style="display: flex;justify-content: space-between;">
                        <div style="margin-left: 15px;">{{item.Name}}</div>
                        <div style="margin-right: 15px;"><i class="fa-solid fa-angle-right"></i></div>
                    </div>
                    
                </li>
<!--                <li v-if="user.username!=''" class="side-info">
                    <div @click.stop="getCoin()" >${{user.coin | numFilter}} <i class="fas fa-sync-alt" ></i></div>
                </li> -->
                <!-- <li  @click.stop='click(6)' v-show="user.Username!=''"><div>{{$t('lang.msg171') /*交易*/}}</div></li> -->
                <!-- <li  @click.stop='hide();$parent.$parent.switchStype(0)'><div>{{$t('prod.item6') /*自由交易*/}}</div></li> -->
                <!-- <li  @click.stop='hide();$parent.$parent.switchStype(1)'><div>{{$t('prod.item7') /*固定資產*/}}</div></li> -->
                <!-- <li  @click.stop='click(9)'><div>{{$t('lang.msg37') /*交易紀錄*/}}</div></li> -->
<!--                <li v-if="user.Username==''"  @click.stop='click(1)'><div>{{$t('lang.msg9') /*登錄*/}}</div></li>
                <li v-if="user.Username==''" @click.stop='click(2)'><div>{{$t('lang.msg10') /*註冊*/}}</div></li> -->
                <!-- <li  @click.stop='click(3)'><div>{{$t('lang.msg137') /*系统公告*/}}</div></li> -->
<!--                <li  @click.stop='click(4)'><div>{{$t('lang.msg165') /*新聞中心*/}}</div></li>
                <li  @click.stop='click(5)'><div>{{$t('lang.msg166') /*視頻中心*/}}</div></li> -->
                <!-- <li  @click.stop='click(8)'><div>{{$t('lang.msg163') /*客服*/}}</div></li> -->
                <!-- <li  @click.stop='click(1)'><div>{{$t('商品說明')}}</div></li> -->
                <!-- <li  @click.stop='click(2)'><div>{{$t('下單紀錄')}}</div></li> -->
                <!-- <li  @click.stop='click(3)'><div>開獎紀錄</div></li>
                <li  @click.stop='click(4)'><div>冷熱遺漏</div></li>
                <li  @click.stop='click(5)' v-if="options.group_type!=12 && options.group_type!=15 && options.group_type!=17"><div>長龍走勢</div></li> -->
<!--                <li  @click.stop='setCookie("en")' ><div>English</div></li>
                <li  @click.stop='setCookie("vi-VN")' ><div>Tiếng Việt</div></li>
                <li  @click.stop='setCookie("zh-TW")' ><div>繁体中文</div></li>
                <li  @click.stop='setCookie("cn")' ><div>简体中文</div></li> -->
            </ul>
        </cube-scroll>
        <div class="keyboard_block" @click.stop='hide()'></div>
        <transition name="filtermenu">
            <component :is="default_view" v-if="show_view" v-on:switchView="CallView"></component>
        </transition>
    </div>
</template>

<script type="text/ecmascript-6">
import { mapGetters } from "vuex";
import { globalMixin } from '@/mixin.js';
import { Locale } from 'cube-ui'
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            default_view:null,
            show_view:false,
            showme:false,
        }
    },  
    props:{
        options: {
            type: Object,
            default() {
            return {}
            }
        }
    },
    computed: {
		...mapGetters({
            user: "user",
            config: 'config',
        }),
    },    
    methods: {
        CallView()
        {
            this.show_view=false;
        },        
        getCoin(){
            this.$store.dispatch('getCoin');
        },
        hide(){
            this.$emit('callback',0);
        },
        ChangVendor(item){
            this.$parent.$parent.VendorID = item.Vendor
            this.$parent.$parent.getLog()
            this.$parent.$parent.ChagneVendor()
            this.hide()
        },
        click(num){
            //this.$emit('callback',0);
            switch(num)
            {
                case 1:
                    this.go2page('/login')
                    //this.$emit('switchView',"rule");
                    // var page="/rule/"+this.options.model+"/"+this.options.type_name;
                    // this.$router.push(page);
                    break;
                case 2:
                    this.go2page('/reg')
                    //this.$router.push("/user/betlog");
                    break;
                case 3:
                    //this.$emit('switchView',"codelist");
                    //this.$router.push("/game/codelist/"+this.options.group_type+"/"+this.options.type_id);
                    break;
                case 4:
                    this.go2page('/news')
                    //this.$emit('switchView',"hotlost");
                    //this.$router.push("/game/hotlost/"+this.options.group_type+"/"+this.options.type_id);
                    break;
                case 5:
                    this.go2page('/video')
                    //this.$emit('switchView',"clong");
                    //this.$router.push("/game/clong/"+this.options.group_type+"/"+this.options.type_id);
                    break;
                case 6:
                    this.go2page('/game')
                    break;
                case 7:
                    this.go2page('/user/index')
                    break;
                case 8:
                    this.service()
                    break;
                case 9:
                    this.show_view=true;
                    this.default_view=require('@/views/user/bidlog.vue').default;
                    break;
                case 10:
                    this.go2page('/')
                    break;
            }
        },
    },

}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.side-bar
    position: fixed; 
    bottom: 0px;
    top: 44px;
    right: 0px;
    //left: 0px;
    overflow:hidden;
    z-index: 10000;
    background-color : #121618f0;
    box-shadow: 0 1px 6px #ccc;
    ul
        list-style: none;
    ul>li
        width: 145px;
        text-align: center;
        line-height: 35px;
        font-size: 1rem;
        border-bottom: 1px #666 solid;
        padding: 3px;
        color: $font-white-color
        font-size: 14px;
    .keyboard_block
        position: fixed;
        bottom: 0;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
    .side-body
        z-index: 10000;
        .side-info
            font-size: 0.85rem;
            line-height: 24px;
            display: block;
        .head-bar
            line-height: 20px;        
            .side-log
                font-size: 1.3rem;
                border-radius: 50%;
                padding: 5px;
                background-color: #4a90e2;
                color: #fff;
                border: 2px solid #fff;
                width: 29px;
                margin: auto;
    .user-icon
        color $user-icon-color;
</style>
