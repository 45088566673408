<template>
    <div class="foot-menu">
        <cube-tab-bar
          v-model="selectedLabelDefault"
          :data="tabs"
          @click="clickHandler" 
          >
        </cube-tab-bar>
        <transition name="filtermenu">
            <component :is="default_view" v-if="show_view" v-on:switchView="CallView"></component>
        </transition>
    </div>
</template>
<script type="text/ecmascript-6">
import { mapGetters } from "vuex";
import { globalMixin } from '@/mixin.js'
import { apiLogout } from "@/api.js";
import { Locale } from 'cube-ui'
  export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            default_view:null,
            show_view:false,
            selectedLabelDefault: 1,
            // tabs: [{
            //     label: '首页',
            //     icon: 'cubeic-home icon-size',
            //     value:1,
            // }, {
            //     label: '日誌',
            //     icon: 'fas fa-chart-bar icon-size',
            //     value:2,
            // }, {
            //     label: '充值',
            //     icon: 'cubeic-vip icon-size',
            //     value:7,
            // }, {
            //     label: '客服',
            //     icon: 'cubeic-message icon-size',
            //     value:4,
            // }, {
            //     label: '會員',
            //     icon: 'cubeic-person icon-size',
            //     value:6,
            // }],
        }
    },
    methods: {
        CallView()
        {
            this.show_view=false;
        },
        clickHandler (value) {
            switch(value)
            {
                case 1:
                    this.$router.push("/user/index");
                    //this.$parent.$parent.switchStype(1)
                    break;
                case 2:
                    //this.$router.push("/game");
                    //this.$parent.$parent.switchStype(2)
                    this.logout()
                    break;
                case 7:
                    this.show_view=true;
                    this.default_view=require('@/views/user/bidlog.vue').default;
                    break;
                // case 3:
                //     this.$router.push("/index/reg");
                //     break;
                // case 4:
                //     this.show_view=true;
                //     this.default_view=require('@/pages/user/betlog.vue').default;
                //     break;
                // case 5:
                //     this.$router.push("/index/login");
                //     break;
                case 6:
                    this.$router.push("/user/index");
                    break;
                // case 7:
                //     this.show_view=true;
                //     this.default_view=require('@/components/rule/index.vue').default;
                //     break;
            }        
        },
        logout()
        {
            apiLogout({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.toast(
                            this.$t('lang.msg139'),
                            {
                                func: () => {
                                    this.$store.dispatch('clearUserInfo');
                                    //this.$router.push("/");
                                    this.$router.push("/login");
                                }
                            });
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },        
    },
    computed: {
		...mapGetters({
            user: "user",
        }),
        tabs()
        {
            return[
                {
                    label: this.$t('prod.item10'),
                    icon: 'fa-solid fa-user icon-size', 
                    value:1,
                }, {
                    label: this.$t('prod.item11'),
                    icon: 'fa-solid fa-headset icon-size',
                    value:2,
                }, 
                // {
                //     label: this.$t('lang.msg161'),
                //     icon: 'fas fa-chart-bar icon-size',
                //     value:7,
                // }, {
                //     label: this.$t('lang.msg163'),
                //     icon: 'cubeic-message icon-size',
                //     value:4,
                // }, {
                //     label: this.$t('lang.msg164'),
                //     icon: 'cubeic-person icon-size',
                //     value:6,
                // },
            ]
        }
    },
    created() {
        switch(true)
        {
            case this.$route.path=="/user/index":
                this.selectedLabelDefault=6;
                break;
        }
    }    
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>


</style>
