<template>
    <MainPage :title="getVname()"   v-on:callback="back" v-on:callmore="switchBar" :noback="true">
        <div slot="content">
            <div class="main-index main-bg-def"  style="z-index: 1;">
<!--                <div style="display: flex;justify-content: space-around;line-height: 30px;margin-bottom: 8px;margin-top: 6px;">
                    <div>
                        <div @click="switchStype(0)"><i class="fa-solid fa-earth-asia"></i> {{$t('prod.item6')}}</div>
                        <div class="switch-underline"></div>
                    </div>
                    <div>
                        <div @click="switchStype(1)"><i class="fa-solid fa-street-view"></i> {{$t('prod.item7')}}</div>
                        <div class="switch-underline"></div>
                    </div>
                </div> -->
                <div class="main-Bulletin">
                    <i class="fas fa-bullhorn icon-bulletin"></i>
                    <Swiper :options="swiperOptionBulletin" >
                        <SwiperSlide v-for="(item, index) in swiperBulletin" :key="index" @click.native="onItemClick(index)">
                            {{item.Title}}
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div style="text-align: end;margin-top: 10px;margin-right: 10px;">
                    ${{user.Coin | numFilter}} <span @click="ReloadCoin()"><i class="fas fa-sync-alt" ></i></span>
                </div>
                <cube-scroll  ref="scroll" :data="prodLog.results" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh" @pulling-up="logLoadMore">
                    <!-- <img src="/images/banner/game.png" style="width: 100%;"> -->
                    <div style="display: table;overflow: hidden;width: 100%;">
                        <div v-for="(item, index) in prodLog.results" class="prod-block" :key="index" >
                            <div class="prod-item" >
                                <div class="prod-item-content" style="text-align: center;">
                                    <div>{{item.Tables}}</div>
                                    <div>{{$t('lang.msg272')}} : <span :class="'color'+numcolor(item)">{{item.Numbers}}</span></div>
                                </div>
                                <div v-if="item.Status=='close'">
                                    <cube-button  @click="bid(item)" class="az-btn" >
                                        <div>$1</div>
                                        <div style="margin-top: 8px;">獲取預測</div>
                                    </cube-button>
                                </div>
                                <div v-if="item.Status=='calc'" >
                                    <div class="msg-btn">
                                        預測計算中... <i class="fa-solid fa-robot an"></i>
                                    </div>
                                </div>
                                <div v-if="item.Status=='open' || item.Status=='lost' ">
                                    <div >
                                        <img :src="require('@/assets/images/player.png')" style="height: 81px;margin-top: 5px;" v-if="item.Res=='PLAYER'">
                                        <img :src="require('@/assets/images/banker.png')" style="height: 81px;margin-top: 5px;" v-if="item.Res=='BANKER'">
                                        <div v-if="item.Res==''" class="msg-btn">
                                            更新線路中... <i class="fa-solid fa-robot an"></i>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.Status=='win'" >
                                    <div class="msg-btn">
                                        <img :src="require('@/assets/images/winner.png')" style="width: 100%;height: 82px;margin-top: -20px;">
                                    </div>
                                </div>
                                <div v-if="item.Status=='remap'" >
                                    <div class="msg-btn">
                                        <span class="color-remap">更新算法中... <i class="fa-solid fa-robot an"></i></span>
                                    </div>
                                </div>
                                <div v-if="item.Status=='poor'" >
                                    <div class="msg-btn">
                                        <span class="color-poor">更新線路中... <i class="fa-solid fa-robot an"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="prod-line"></div> -->
                        </div>
                        <div class="no-record" v-if="!prodLog.results.length">
                            <img class="no-record-img" src="@/assets/images/user/norecord.png">
                            <div class="no-record-txt">{{this.$t('lang.msg35')}}</div>
                        </div>
                    </div>
                </cube-scroll>
                <Tabbar></Tabbar>
            </div>
            <!-- <ChkDoc v-if="showDetail"></ChkDoc> -->
            <transition name="gamemenu">
              <SideBar v-if="side_bar"  v-on:callback="switchBar" ></SideBar>
            </transition>
            <div v-show="showDetail" >
                <div class="news-layout">
                    <div style="text-align: end;"><span class="markx"><i class="fa-solid fa-xmark" @click="closeClick"></i></span></div>
                    <cube-scroll  ref="scroll2" >
                        <div style="margin-top: 10px;font-size: 16px;line-height: 20px; padding: 0px 15px;">
                            {{Detail.Title}}
                        </div>
                        <!-- <div v-html="Detail.Content" style="margin-top: 10px;font-size: 16px;line-height: 20px;"></div> -->
                       <div style="margin-top: 10px;margin-bottom: 10px;;font-size: 16px;line-height: 20px; padding: 0px 15px;">
                            <pre>{{Detail.Content}}</pre>
                        </div>
                    </cube-scroll>
                </div>
                <div class="keyboard_block" ></div>
            </div>
        </div>
    </MainPage>
</template>
<script>
import Tabbar from '@/components/tab-bar.vue';
import SideBar from '@/components/side-bar-game.vue';
import { Locale } from 'cube-ui'
// import ChkDoc from '@/components/chkdoc.vue'
import MainPage from '@/components/nav-bar-game.vue'
import { mapGetters } from "vuex";
import { apiGameList,apiBid,apiVendorList,apiBidClose,apiBidUpdate,apiBulletin } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { Swiper, SwiperSlide, } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            showDetail:false,
            Detail:{
                Title:'',
                Content:'',
            },
            side_bar:false,
            VendorID:'WM',
            prodLog:{
                results:[],
            },
            swiperBulletin:[],
            swiperOptionBulletin: {
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false,
                },
                // swiper configs 所有的配置同swiper官方api配置
                // speed: 1000,
                // slidesPerView: "auto", // swiper-slider宽度自适应
                // setWrapperSize: true,
                // autoHeight: true,
                // mousewheelControl: true,
            },
            log: {
                y: 0,
                col: 2,
                data: [],

                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: ' ', //'Resfresh Success',

                pullUpLoadThreshold: 0,
                pullUpLoadMoreTxt: ' ',  //'Load more',
                pullUpLoadNoMoreTxt: '',    //'No more data',
            },
            //current_page:1,
            show_view:false,
            default_view:null,
        }
    },
    methods: {
        numcolor(item){
            if(item.Numbers % 2 ==0){
                return "s"
            }else{
                return "d"
            }
        },
		initWebSocket(){ //初始化weosocket
			//const wsuri = "wss://stream.binance.com:9443/ws/BNBBTC@kline_m";
			//const wsuri = "wss://stream.binance.com:9443/ws/btcusdt@ticker";
			const wsuri = this.config.wsURL;
			this.websock = new WebSocket(wsuri);
			this.websock.onmessage = this.websocketonmessage;
			this.websock.onopen = this.websocketonopen;
			this.websock.onerror = this.websocketonerror;
			this.websock.onclose = this.websocketclose;
		},
		websocketonopen(){ //連線建立之後執行send方法發送數據
            //let actions = {"Symbol":this.options.type_name,"start_time":this.last.actionTime,"type_id":this.options.type_id};            
            //console.log(this.websock)
            this.ChagneVendor()
            this.Reping()
		},
        Reping(){
            clearInterval(this.ChkSocket);
            if(this.websock.readyState != this.websock.OPEN){
                //this.toast("您已經離線,請重新連線",null,0);
                this.dialog('alert',"離線","您已經離線,請重新連線");
                this.initWebSocket();
            }
            let actions = {"Action":"Ping"};
            this.websocketsend(JSON.stringify(actions));
            //this.websocketsend("ping");
            this.ChkSocket = setInterval(() => {
                    this.Reping();
            }, 15000);
        },
        ChagneVendor(){
            // let actions = {"Symbol":this.options.type_name,"start_time":this.last.actionTime,"type_id":this.options.type_id};
            // this.websocketsend(JSON.stringify(actions));
            let actions = {"Vendor":this.VendorID,"Action":"Vendor"};
            this.websocketsend(JSON.stringify(actions));
        },
		websocketonerror(){//連線建立失敗重連
            console.log("connect")
			this.initWebSocket();
		},
		websocketonmessage(e){ //數據接收
            //console.log(e)
            const wsdata = JSON.parse(e.data);
            //console.log(wsdata)
            if(this.VendorID != wsdata.vendor){
                return
            }
            let temp = {Vendor:wsdata.vendor,Tables:wsdata.tables}
            let Tables = this.GetTable(temp)
            //console.log(Tables)
            if(!Tables){
                return
            }
            switch(true){
                case wsdata.action == "Vendor":
                    //console.log(temp)
                    if(Tables.Status == "open"){
                        //console.log("chk res")
                        this.BidClose(Tables)
                        break;
                    }
                    if(Tables.Status == "close"){
                        this.OpenTable(temp,"calc")
                        break;
                    }
                    if(Tables.Status == "poor" || Tables.Status == "remap" || Tables.Status == "win" || Tables.Status == "lost" || Tables.Status == "calc"){
                        this.BidUpdate(temp)
                        //更新牌桌狀態 BidUpdate
                        break;
                    }
                break;
            }
        },
		websocketsend(Data){//數據發送
			this.websock.send(Data);
		},        
		websocketclose(e){  //關閉
			console.log('斷開連線',e);
            //console.log("自動重連")
			//this.websocketonerror();
		},
        switchBar()
        {
            this.side_bar=!this.side_bar;
        },
        back()
        {
          this.$router.back()
          //this.$emit('switchView');
        },
        BidUpdate(item){
            apiBidUpdate({
                Vendor: item.Vendor,
                Tables: item.Tables,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.UpdateTable(item,response.data.data.chk,response.data.data.res,response.data.data.numbers)
                    }
                    return;
                }).catch(error => {
                    this.$createToast().hide();
                    console.log(error);
                    return;
                });
        },
        BidClose(item){
            apiBidClose({
                Vendor: item.Vendor,
                Tables: item.Tables,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.OpenTable(item,response.data.data.chk)
                    }
                    return;
                }).catch(error => {
                    this.$createToast().hide();
                    console.log(error);
                    return;
                });
        },
        bid(item){
            apiBid({
                Vendor: item.Vendor,
                Tables: item.Tables,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        //this.toast(this.$t('lang.msg26'));
                        //this.dialog('alert',this.$t('lang.msg26'),this.$t('lang.msg26'));
                        this.OpenTable(response.data.data,"open")
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        UpdateTable(item,status,data,number){
            //console.log(status,data,number)
            let fixNumber = 0
            for(var n in this.prodLog.results){
                if(this.prodLog.results[n].Vendor == item.Vendor && this.prodLog.results[n].Tables == item.Tables){
                    if(this.prodLog.results[n].Status=="win"){
                        fixNumber = n
                        setTimeout(() => {
                            this.prodLog.results[fixNumber].Res = data
                            this.prodLog.results[fixNumber].Status = status
                            this.prodLog.results[fixNumber].Numbers = number
                        }, 1000);
                    }else{
                        this.prodLog.results[n].Res = data
                        this.prodLog.results[n].Status = status
                        this.prodLog.results[n].Numbers = number
                    }
                }
            }
        },
        OpenTable(item,status){
            for(var n in this.prodLog.results){
                if(this.prodLog.results[n].Vendor == item.Vendor && this.prodLog.results[n].Tables == item.Tables){
                    this.prodLog.results[n].Res = item.Res
                    this.prodLog.results[n].Status = status
                }
            }
        },
        GetTable(item){
            for(var n in this.prodLog.results){
                if(this.prodLog.results[n].Vendor == item.Vendor &&  this.prodLog.results[n].Tables == item.Tables){
                    return this.prodLog.results[n]
                }
            }
        },
        getLog()
        {
            apiGameList({
                VendorID: this.VendorID,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.prodLog.results = [];
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }
                        this.prodLog.results=response.data.data;
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        getVendor(){
            apiVendorList({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status && response.data.data.length > 0)
                    {
                        this.$store.dispatch('setVendor',response.data.data);
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getVname(){
            for(var n in this.config.vendor_list){
                if(this.config.vendor_list[n].Vendor == this.VendorID){
                    return this.config.vendor_list[n].Name
                }
            }
        },
        logRefresh(){
            this.current_page=1;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
        logLoadMore(){
            this.current_page++;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
        closeClick(){
            this.showDetail=0;
        },
        onItemClick(index)
        {
            //this.dialog('alert',this.results[index].Title,this.results[index].Content);
            this.showDetail=1;
            this.Detail.Title = this.swiperBulletin[index].Title
            this.Detail.Content = this.swiperBulletin[index].Content
            setTimeout(() => {
                this.$refs.scroll2.refresh()
            }, 500);
        },
        getBulletin(page)
        {
            apiBulletin({
                page: page,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            return;
                        }
                        this.swiperBulletin = response.data.data
                        //console.log(this.swiperBulletin)
                        // for(var k in response.data.data)
                        // {
                        //   this.swiperBulletin.push(response.data.data[k].Title);
                        // }
                        // this.results=this.results.concat(response.data.data);
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            user: "user",
            config: "config",
        }),
        scrollLogOpts() {
            return {
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                pullUpLoad: {
                    threshold: parseInt(this.log.pullUpLoadThreshold),
                    txt: {
                        more: this.log.pullUpLoadMoreTxt,
                        noMore: this.$t('lang.err3')
                    }
                },
                scrollbar: true
            }
        },
    },
    components: {
        MainPage,
        Swiper,
        SwiperSlide,
        SideBar,
        Tabbar,
    },
    watch:{
		'config.wsURL'() {
			if(this.config.wsURL != ""){
                this.initWebSocket();
                //this.ChagneVendor(this.VendorID)
            } 
		}        
    },
    created() {
        if(this.config.wsURL != ""){
            this.initWebSocket();
            //this.ChagneVendor(this.VendorID)
        }
        this.getLog();
        this.getBulletin(1);
        if(this.user.Username=='' || !this.user.Username){
            this.checkLogin();
        }
        //console.log(Object.keys(this.config.vendor_list).length)
        if(Object.keys(this.config.vendor_list).length==0){
            this.getVendor()
        }
    },  
    destroyed: function()
	{
        clearInterval(this.cd);
        clearInterval(this.ChkSocket);
        this.websock.close(); //離開路由之後斷開websocket連線
    } 
}

</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    bottom: 59px;
    .main-Bulletin
        display flex;
        background-color $navbar-bg-color;
        height: 30px;
        line-height 30px;
    .icon-bulletin
        line-height 30px;
        padding 0px 10px;
    .order-flex
        display inline-flex
        padding 10px 0px
    .time-center-content
        text-align left
        display flex
        padding-top: 5px;
        padding-left: 10px;
        .time-center-block
            width: 40px;
            text-align: center;
            height: 40px;
            background: $time-bg-color;
            color: $time-font-color;
            border-radius: 5px;
            line-height: 40px;
        .time-center-split
            width: 16px;
            text-align: center;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
    .prod-item
        height: 110px;
        width: 85%
        padding: 15px 10px;
        background-color: #1b1b1bbf;
        .record-prod-name
            font-size 13px
        .imgIcon
            width 160px
            height 150px
            border-radius: 5px;
        .game-btn
            width 80px
            padding: 10px;
            margin-top 35px
        .az-btn
            padding: 10px 16px;
            margin-top: 10px;
        .msg-btn
            padding: 26px 16px;
            margin-top: 1px;
        .record-content
            line-height: 25px
            padding-left 10px
            padding-right 0px
            .record-icon-size
                width 22px;
            .record-txt
                font-size 14px
                display flex
    .switch-underline
        height 5px
        background-color $switch-underline-color
        border-radius 5px
    .prod-block
        margin-top: 25px;
        //background: $prod-black-color;
        text-align: center;
        border-radius: 5px;
        width: 49%;
        float: left;
    .prod-item-content
        line-height: 22px;
        text-align: left;
        font-size: 14px;
        justify-content: space-between;
        display: flex;
    .colors
        color:#0cf80f
    .colord
        color:#ffe011
    .color-remap
        color:#d00000
    .color-poor
        color:#00c691
@-webkit-keyframes rotation{
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}
.an{
    -webkit-transform: rotate(360deg);
    animation: rotation 4s linear infinite;
    -moz-animation: rotation 4s linear infinite;
    -webkit-animation: rotation 4s linear infinite;
    -o-animation: rotation 4s linear infinite;
}
</style>
